import { showRandomImage, clearImage } from "./images";

// Сложение
function plus(a, b) {
  return a + b;
}

const sum_form = document.getElementById("plus_form");
sum_form.addEventListener("submit", (event) => {
  event.preventDefault();
  const data = new FormData(event.target);
  const result = plus(
    Number(data.get("number_1")),
    Number(data.get("number_2"))
  );
  event.target.querySelector(".calc_result").innerHTML = result;
  showRandomImage();
});

// Вычитание
function minus(a, b) {
  return a - b;
}

const sum_form2 = document.getElementById("minus_form");
sum_form2.addEventListener("submit", (event) => {
  event.preventDefault();
  const data = new FormData(event.target);
  const result = minus(
    Number(data.get("number_1")),
    Number(data.get("number_2"))
  );
  event.target.querySelector(".calc_result").innerHTML = result;
  showRandomImage();
});

// Умножение
function mul(a, b) {
  return a * b;
}

const sum_form3 = document.getElementById("mul_form");
sum_form3.addEventListener("submit", (event) => {
  event.preventDefault();
  const data = new FormData(event.target);
  const result = mul(
    Number(data.get("number_1")),
    Number(data.get("number_2"))
  );
  event.target.querySelector(".calc_result").innerHTML = result;
  showRandomImage();
});

// Деление
function div(a, b) {
  return a / b;
}
const sum_form4 = document.getElementById("div_form");
sum_form4.addEventListener("submit", (event) => {
  event.preventDefault();
  const data = new FormData(event.target);
  const result = parseFloat(
    div(Number(data.get("number_1")), Number(data.get("number_2"))).toFixed(2)
  );
  event.target.querySelector(".calc_result").innerHTML = result;
  showRandomImage();
});

// Tabs

const tabs = document.querySelectorAll("#tabs li");
const forms = document.querySelectorAll("form");

tabs.forEach((tab) => {
  tab.addEventListener("click", () => {
    const id = tab.id;
    tabs.forEach((tab) => tab.classList.remove("active"));
    tab.classList.add("active");

    forms.forEach((form) => {
      if (form.id === `${id}_form`) {
        form.classList.remove("hidden");
      } else {
        form.classList.add("hidden");
      }
    });

    clearForms();
    clearImage();
  });
});

function clearForms() {
  document.querySelectorAll('input').forEach(input => input.value = undefined)
  document.querySelectorAll('.calc_result').forEach(el => el.innerHTML = '')
}
