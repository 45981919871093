import image1 from '../images/moisha/1.jpeg';
import image2 from '../images/moisha/2.jpeg';
import image3 from '../images/moisha/3.jpeg';
import image4 from '../images/moisha/4.jpeg';
import image5 from '../images/moisha/5.jpeg';
import image6 from '../images/moisha/6.jpeg';
import image7 from '../images/moisha/7.jpeg';

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
]
let imagesIndex = Math.floor(Math.random() * (images.length - 1));
const image = document.getElementById("image");

const titles = [
  'Результат&nbsp;верный...',
  'Неплохо...',
  'Моя&nbsp;школа :)',
  'С калькулятором все умеют...',
  'А&nbsp;в&nbsp;уме&nbsp;сможешь?',
  'Я бы быстрее посчитал...',
]
let titleIndex = Math.floor(Math.random() * (titles.length - 1));
const image_title = document.getElementById("image_title");

export function showRandomImage() {
  imagesIndex++;
  const path = images[imagesIndex % images.length];
  image.src = path;

  titleIndex++;
  const title = titles[titleIndex % titles.length];
  image_title.innerHTML = title;
}

export function clearImage() {
  image.src = '';
  image_title.innerHTML = '';
}
